import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import storeCreator from "./store";
import config from "./config";
import { setUser } from "./store/reducers/user";

const axios = require("axios");

let axiosApiUrl = "";
let baseUrl = window.location.hostname;
if (baseUrl === "localhost") {
  axiosApiUrl = "http://localhost:8000";
} else if (baseUrl === "delpueblofarmacias.com.ar") {
  axiosApiUrl = "https://api.delpueblofarmacias.com.ar";
} else if (baseUrl === "staging.delpueblofarmacias.com.ar") {
  axiosApiUrl = "https://staging.api.delpueblofarmacias.com.ar";
} else if (baseUrl === "qa.delpueblofarmacias.com.ar") {
  axiosApiUrl = "https://qa.api.delpueblofarmacias.com.ar";
}
axios.defaults.baseURL = axiosApiUrl;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

if (localStorage.token) {
  axios.defaults.headers.common["Authorization"] = "JWT " + localStorage.token;
}

const store = storeCreator();

if (localStorage.user) {
  const user = setUser(store.dispatch, JSON.parse(localStorage.user).id);
}

const app = (
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
