import { reset } from "redux-form";
import * as actionTypes from "../actions/user";

const axios = require("axios");

const initialState = {
  isLoading: false,
  message: [],
  users: [],
  user: null,
  group: null,
  groups: [],
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case actionTypes.SET_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case actionTypes.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionTypes.MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
}

export const listUsers = () => (dispatch) => {
  dispatch({ type: actionTypes.MESSAGE, payload: {} });
  axios.get("/users/").then(function (response) {
    dispatch({ type: actionTypes.SET_USERS, payload: response.data });
  });
};

export const listGroups = () => (dispatch) => {
  axios.get("/groups/").then(function (response) {
    dispatch({ type: actionTypes.SET_GROUPS, payload: response.data });
  });
};

export const createUser =
  (data, deliveryman = false, callback = null) =>
  (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE, payload: {} });
    dispatch({ type: actionTypes.LOADING, payload: true });
    console.log("data redux before", data);
    let aux = data.groups;
    if (deliveryman) {
      data.groups = [4];
      data.carrier = JSON.parse(localStorage.user).carrier;
    }
    console.log("data redux after", data);
    data.groups = [parseInt(data.groups)];
    if (data.is_active === undefined) data.is_active = true;

    axios
      .post("/users/", data)
      .then(function (response) {
        dispatch({ type: actionTypes.LOADING, payload: false });

        if (response.status == 201) {
          dispatch({
            type: actionTypes.MESSAGE,
            payload: {
              status: response.status,
              type: "success",
              text: null,
              data: response.data,
            },
          });
        }

        if (callback) callback(response);
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.LOADING, payload: false });

        try {
          dispatch({
            type: actionTypes.MESSAGE,
            payload: {
              type: "danger",
              text: error.response.data.non_field_errors[0],
            },
          });
        } catch (err) {}

        try {
          let errors = error.response.data;
          let message = [];
          for (var item in errors) {
            errors[item].forEach((text, key) => {
              message.push({
                type: "danger",
                text: text,
              });
            });
          }
          dispatch({ type: actionTypes.MESSAGE, payload: message });
        } catch (err) {}
      });
  };

export const setPassword = (data) => (dispatch) => {
  dispatch({ type: actionTypes.MESSAGE, payload: {} });
  dispatch({ type: actionTypes.LOADING, payload: true });

  axios
    .post("/users/me/set_password/", data)
    .then(function (response) {
      dispatch({ type: actionTypes.LOADING, payload: false });

      if (response.status === 200) {
        dispatch({
          type: actionTypes.MESSAGE,
          payload: {
            status: response.status,
            type: "success",
            text: "Contraseña guardada",
          },
        });
      }
    })
    .catch(function (error) {
      dispatch({ type: actionTypes.LOADING, payload: false });

      try {
        dispatch({
          type: actionTypes.MESSAGE,
          payload: { type: "danger", text: "Error al guardar la contraseña" },
        });
      } catch (err) {}
    });
};

export const editUser = (id, data) => (dispatch) => {
  dispatch({ type: actionTypes.MESSAGE, payload: {} });
  dispatch({ type: actionTypes.LOADING, payload: true });
  data.groups = [parseInt(data.groups)];
  console.log("data en edit user es", data);
  axios
    .put(`/users/${id}/`, data)
    .then(function (response) {
      dispatch({ type: actionTypes.LOADING, payload: false });

      if (response.status == 200) {
        dispatch({
          type: actionTypes.MESSAGE,
          payload: {
            status: response.status,
            type: "success",
            text: null,
            data: response.data,
          },
        });
      }
    })
    .catch(function (error) {
      dispatch({ type: actionTypes.LOADING, payload: false });

      try {
        dispatch({
          type: actionTypes.MESSAGE,
          payload: {
            type: "danger",
            text: error.response.data.non_field_errors[0],
          },
        });
      } catch (err) {}

      try {
        let errors = error.response.data;
        let message = [];
        for (var item in errors) {
          errors[item].forEach((text, key) => {
            message.push({
              type: "danger",
              text: text,
            });
          });
        }
        dispatch({ type: actionTypes.MESSAGE, payload: message });
      } catch (err) {}
    });
};

export const setUser = (dispatch, id) => {
  axios.get(`/users/${id}/`).then(function (response) {
    let groupsName = [];
    let groups = JSON.parse(localStorage.user).groups;
    if (groups) {
      groups.map((item, key) => {
        groupsName.push(item.name);
      });
    }
    response.data.groupsName = groupsName;
    dispatch({ type: actionTypes.SET_USER, payload: response.data });
  });
};

export const setUserById = (id) => async (dispatch) => {
  await axios.get(`/users/${id}/`).then(function (response) {
    console.log("respuesta es ----->", response.data);
    dispatch({ type: actionTypes.SET_USER, payload: response.data });
  });
};

export const resetUser = (id) => (dispatch) => {
  dispatch({ type: actionTypes.SET_USER, payload: null });
  dispatch(reset("user"));
};
