import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducer';
let middlewares;


if (process.env.NODE_ENV === 'production')
	middlewares = applyMiddleware(thunk);
else
	middlewares = applyMiddleware(thunk, createLogger());

function create(initialState) {
	const store = createStore(
		rootReducer,
		initialState,
		compose(
			middlewares,
			window.devToolsExtension ? window.devToolsExtension() : f => f
		)
	);


	if (module.hot) {
		module.hot.accept('./reducer', () => {
			store.replaceReducer(require('./reducer').default); // eslint-disable-line
		});
	}

	return store;
}

export default create;