/* eslint-disable default-case */
/* eslint-disable no-loop-func */
import * as actionTypes from "../actions/order";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { reducer } from "redux-form";

const axios = require("axios");

let start_date = new Date().setMonth(new Date().getMonth() - 1);
const initialState = {
	orders: [],
	ordersFinalized: [],
	order: null,
	hasMore: true,
	page: 1,
	start_date_date: new Date().setMonth(new Date().getMonth() - 1),
	end_date_data: new Date(),
	start_date: format(new Date(start_date), "yyyy-MM-dd HH:mm"),
	end_date: format(new Date(), "yyyy-MM-dd HH:mm"),
	pending: true,
	inProgress: true,
	collected: true,
	finalized: false,
	status: ["pending", "in-progress", "collected"],
	branch: null,
	message: null,
	last_update: null,
};

var interval = null;

export default function order(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SET_ORDERS:
			return {
				...state,
				orders: state.orders.concat(
					action.payload.filter(
						(orden) =>
							!state.orders
								.map((orden) => {
									return orden?.order_id || null;
								})
								.includes(orden?.order_id || null)
					)
				),
			};

		case actionTypes.SET_ORDERS_FINALIZED:
			return {
				...state,
				ordersFinalized: state.ordersFinalized.concat(
					action.payload.filter(
						(orden) =>
							!state.ordersFinalized
								.map((orden) => {
									return orden?.order_id || null;
								})
								.includes(orden?.order_id || null)
					)
				),
			};
		case actionTypes.UPDATE_ORDERS:
			return {
				...state,
				orders: action.payload,
			};
		case actionTypes.SET_LAST_UPDATE:
			return {
				...state,
				last_update: action.payload,
			};
		case actionTypes.UPDATE_ORDER:
			var orders = state.orders;
			orders.forEach((item, key) => {
				if (item.order_id === action.payload.order_id) {
					orders[key] = action.payload;
				}
			});
			return {
				...state,
				orders: orders,
			};
		case actionTypes.DELETE_ORDER:
			var orders = state.orders;
			orders.forEach((item, key) => {
				if (item.order_id === action.payload) {
					delete orders[key];
				}
			});
			return {
				...state,
				orders: orders,
			};
		case actionTypes.CLEAR_ORDERS:
			return {
				...state,
				orders: [],
			};
		case actionTypes.CLEAR_ORDERS_FINALIZED:
			return {
				...state,
				ordersFinalized: [],
			};
		case actionTypes.SET_ORDER:
			return {
				...state,
				order: action.payload,
			};
		case actionTypes.SET_HAS_MORE:
			return {
				...state,
				hasMore: action.payload,
			};
		case actionTypes.SET_PAGE:
			return {
				...state,
				page: action.payload,
			};
		case actionTypes.SET_START_DATE:
			return {
				...state,
				...action.payload,
			};
		case actionTypes.SET_END_DATE:
			return {
				...state,
				...action.payload,
			};
		case actionTypes.SET_STATUS_PENDING:
			return {
				...state,
				pending: action.payload,
			};
		case actionTypes.SET_STATUS_INPROGRESS:
			return {
				...state,
				inProgress: action.payload,
			};
		case actionTypes.SET_STATUS_COLLECTED:
			return {
				...state,
				collected: action.payload,
			};
		case actionTypes.SET_STATUS_FINALIZED:
			return {
				...state,
				finalized: action.payload,
			};
		case actionTypes.SET_STATUS:
			return {
				...state,
				status: action.payload,
			};
		case actionTypes.SET_BRANCH:
			return {
				...state,
				branch: action.payload,
			};
		case actionTypes.SET_MESSAGE:
			return {
				...state,
				message: action.payload,
			};
		default:
			return state;
	}
}

export const bringOrders = (url) => (dispatch) => {
	axios.get(url).then((response) => {
		dispatch({
			type: actionTypes.SET_ORDERS_FINALIZED,
			payload: response.data,
		});
	});
};

export const listOrders =
	(start_date, end_date, page = 1, status = null, branch = null) =>
		(dispatch, getState) => {
			let qs_status = status ? `&status=${status}` : "";
			let qs_branch = branch ? `&branch=${branch}` : "";
			var url = `/orders/?creation_date=[${start_date}TO${end_date}]&page=${page}${qs_status}${qs_branch}`;
			var updated_at__gt = getState().order.updated_at__gt;
			axios.get(url).then(async function (response) {

				let ordersFiltered = cleanAcordingToCarrier(response.data);

				let auxOrders = []
				while (true) {

					if (ordersFiltered.length > 0) {
						break
					}

					page += 1
					url = `/orders/?creation_date=[${start_date}TO${end_date}]&page=${page}${qs_status}${qs_branch}`
					let res = await axios.get(url)

					if (!res.data.length) {
						break
					}

					let newFiltered = cleanAcordingToCarrier(res.data)

					if (newFiltered.length > 0) {
						auxOrders = flatArray(auxOrders.push(newFiltered))
						if (auxOrders.length >= 4) {
							ordersFiltered = newFiltered
							break
						}
					}
				}

				pagination(page)

				dispatch({
					type: actionTypes.SET_ORDERS,
					payload: ordersFiltered || [],
				});

				dispatch({
					type: actionTypes.SET_HAS_MORE,
					payload: response.data.length > 0,
				});

				try {
					clearInterval(interval);
				} catch (err) {
					//pass
				}

				interval = setInterval(function () {
					var url = `/orders/?creation_date=[${start_date}TO${end_date}]&page=${page}${qs_status}${qs_branch}`;
					axios.get(`${url}&full=true`).then(function (response) {
						const ordersFiltered = cleanAcordingToCarrier(response.data);
						dispatch({
							type: actionTypes.UPDATE_ORDERS,
							payload: ordersFiltered || [],
						});
					});
				}, 60000);
			});
		};

export const getOrder = (orderId) => (dispatch) => {
	dispatch({ type: actionTypes.SET_ORDER, payload: null });
	axios.get(`/orders/${orderId}/`).then(function (response) {
		dispatch({ type: actionTypes.SET_ORDER, payload: response.data });
	});
};

export const setOwner = (orderId, callback, error) => (dispatch, getState) => {
	let carrier = JSON.parse(localStorage.user).carrier;
	axios
		.patch(`/orders/${orderId}/`, { carrier: carrier })
		.then(function (response) {
			if (response.status === 200) {
				dispatch({ type: actionTypes.UPDATE_ORDER, payload: response.data });
			}
			callback(response);
		})
		.catch(function (error) {
			if (error.response) {
				if (error.response.status === 400) {
					dispatch({
						type: actionTypes.SET_MESSAGE,
						payload: {
							type: "Advertencia",
							text: "Esta orden ya ha sido tomada por otra cadeteria.",
						},
					});
					setTimeout(function () {
						dispatch({ type: actionTypes.SET_MESSAGE, payload: null });

						let orders = getState().order.orders;
						orders.forEach((item, key) => {
							if (item.order_id === orderId) {
								delete orders[key];
							}
						});
						dispatch({ type: actionTypes.DELETE_ORDER, payload: orderId });
					}, 5000);
				}
			}
		});
};

export const assign = (orderId, userId, callback) => (dispatch) => {
	axios
		.patch(`/orders/${orderId}/`, { user: userId })
		.then(function (response) {
			if (response.status === 200) {
				dispatch({ type: actionTypes.UPDATE_ORDER, payload: response.data });
			}
			callback(response);
		});
};

export const finish = (orderId, callback) => (dispatch) => {
	axios
		.patch(`/orders/${orderId}/`, { status: "finalized" })
		.then(function (response) {
			if (response.status === 200) {
				dispatch({ type: actionTypes.UPDATE_ORDER, payload: response.data });
			}
			callback(response);
		});
};

export const collect = (orderId, callback) => (dispatch) => {
	axios
		.patch(`/orders/${orderId}/`, { status: "collected" })
		.then(function (response) {
			if (response.status === 200) {
				dispatch({ type: actionTypes.UPDATE_ORDER, payload: response.data });
			}
			callback(response);
		});
};

export const pagination = (page) => (dispatch, getState) => {
	dispatch({ type: actionTypes.SET_PAGE, payload: page });
};

export const setStartDate = (date) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_START_DATE,
		payload: {
			start_date_date: date,
			start_date: format(new Date(date), "yyyy-MM-dd HH:mm"),
		},
	});
};

export const setEndDate = (date) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_END_DATE,
		payload: {
			end_date_data: date,
			end_date: format(new Date(date), "yyyy-MM-dd HH:mm"),
		},
	});
};

export const clearList = (date) => (dispatch) => {
	dispatch({ type: actionTypes.SET_HAS_MORE, payload: true });
	dispatch({ type: actionTypes.CLEAR_ORDERS, payload: [] });
	dispatch({ type: actionTypes.SET_PAGE, payload: 1 });
};

export const clearOrders = () => (dispatch) => {
	dispatch({ type: actionTypes.CLEAR_ORDERS_FINALIZED, payload: [] });
};

export const setStatus = (data) => (dispatch) => {
	switch (data.status) {
		case "pending":
			dispatch({ type: actionTypes.SET_STATUS_PENDING, payload: data.active });
			break;
		case "inProgress":
			dispatch({
				type: actionTypes.SET_STATUS_INPROGRESS,
				payload: data.active,
			});
			break;
		case "collected":
			dispatch({
				type: actionTypes.SET_STATUS_COLLECTED,
				payload: data.active,
			});
			break;
		case "finalized":
			dispatch({
				type: actionTypes.SET_STATUS_FINALIZED,
				payload: data.active,
			});
			break;
	}

	let currentStatus = data.currentStatus;
	let _status = data.status.replace("inProgress", "in-progress");
	if (currentStatus.indexOf(_status) > -1) {
		currentStatus.splice(currentStatus.indexOf(_status), 1);
	} else {
		currentStatus.push(_status);
	}
	dispatch({ type: actionTypes.SET_STATUS, payload: currentStatus });
};

export const setBranch = (e) => (dispatch) => {
	dispatch({ type: actionTypes.SET_BRANCH, payload: e.target.value });
};

const cleanAcordingToCarrier = (ordenes) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let carrier = user.carrier || null;
	const isSupervisor = user.groups.some(g => g.name == "Supervisor")
	if (isSupervisor) {
		carrier = 4
	}

	let selectedSlaToExclude = null;
	switch (carrier) {
		case 4:
			selectedSlaToExclude = "Envio por Andreani";
			break;
		case 3:
			selectedSlaToExclude = "Envio a domicilio - Sucursal Norte";
			break;
		default:
			return ordenes;
	}
	const filtered = ordenes.filter(orden => {
		const logistics = orden.logistics_info || [];
		const selectedSlas = logistics.map(logistica => logistica.selected_sla);
		return !selectedSlas.includes(selectedSlaToExclude);
	});
	return filtered
};


function flatArray(arr) {
	const result = [];

	function flatten(inputArr) {
		for (let i = 0; i < inputArr.length; i++) {
			if (Array.isArray(inputArr[i])) {
				flatten(inputArr[i]);
			} else {
				result.push(inputArr[i]);
			}
		}
	}

	flatten(arr);
	return result;
}